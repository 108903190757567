import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Menu as AntMenu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isBrowser } from '../utils/utils';

const Menu = props => {
  const data = useStaticQuery(graphql`
    query MainMenuQuery {
      allMainMenuJson {
        edges {
          node {
            name
            url
            weight
            target
          }
        }
      }
    }
  `);

  const getMantasMallPage = () => {
    if (!isBrowser()) {
      window.open('https://mall.mantas.cn/');
      return;
    }
    let url = '';
    if (window.location.origin.includes('localhost')) {
      url = `http://localhost:6204/oom/home`;
      window.open(url);
      return;
    }
    const urls = window.location.origin.split('-');
    if (urls.length === 2) {
      url = `${urls[0]}-mall.mantas.cn`;
    } else {
      url = 'https://mall.mantas.cn/';
    }
    window.open(url);
  };

  const getSupplierRegisterPage = () => {
    if (!isBrowser()) return 'https://supplier.mantas.cn/provider/register';
    let url = '';
    if (window.location.origin.includes('localhost')) {
      url = `https://supplier.petkit.cn/provider/register`;
      return url;
    }
    const urls = window.location.origin.split('-');
    if (urls.length === 2) {
      url = `${urls[0]}-supplier.petkit.cn/provider/register`;
    } else {
      url = 'https://supplier.mantas.cn/provider/register';
    }
    return url;
  };

  const menu = (
    <AntMenu className="main-menu">
      <AntMenu.Item>
        <a
          href="/league"
          className="a_text_add"
        >
          商户加盟
        </a>
      </AntMenu.Item>
      <AntMenu.Item>
        <a
          href={getSupplierRegisterPage()}
          target="_blank"
          rel="noreferrer"
          className="a_text_add"
        >
          供应商入驻
        </a>
      </AntMenu.Item>
    </AntMenu>
  );

  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {data.allMainMenuJson.edges.map(({ node }) => (
          <li key={node.weight}>
            {node.url === '/league' ? (
              <Dropdown overlay={menu}>
                <a style={{ display: 'flex', alignItems: 'center' }}>
                  {node.name}
                  <DownOutlined style={{ marginLeft: '4px' }}/>
                </a>
              </Dropdown>
            ) : (
              <Link
                to={node.url}
                activeClassName="active"
              >
                {node.name}
              </Link>
            )}
          </li>
        ))}
        <li>
          <a onClick={getMantasMallPage} className="menu_a">登录商城</a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
