import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../../static/images/3x/logo/LOGO@3x.png';
import logoMobile from '../../static/images/2x/logo/LOGO@2x.png';
import MenuMobile from './MenuMobile';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
  }

  toggleMenu = menuActive => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive
    }));
  };

  render() {
    const config = this.props.data.configJson;
    return (
      <div>
        <div className="header">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img height={config.logo.desktop_height} alt={config.logo.alt} src={logo} />
              </Link>
            </div>
            <div className="logo-mobile">
              <Link to="/">
                <img height={config.logo.desktop_height} alt={config.logo.alt} src={logoMobile} />
              </Link>
            </div>
            {/* <MenuMobile toggleMenu={this.toggleMenu} active={this.state.menuActive} /> */}
            <Menu />
            {/* <Hamburger toggleMenu={this.toggleMenu} /> */}
          </div>
        </div>
        <div className="header_mask" />
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        configJson {
          logo {
            alt
            desktop
            mobile
            desktop_height
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
