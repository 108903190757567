import React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import SubFooter from './SubFooter';
import Redirect from '../pages/redirect';

import '../scss/style.scss';
import SEO from './SEO';

class Layout extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      isTablet: true,
      isPc: true
    };
  }

  componentDidMount() {
    const { isTablet, isPc } = this.os();
    this.setState({
      isTablet,
      isPc
    });
  }

  os() {
    try {
      const ua = navigator.userAgent;
      const isWindowsPhone = /(?:Windows Phone)/.test(ua);
      const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
      const isAndroid = /(?:Android)/.test(ua);
      const isFireFox = /(?:Firefox)/.test(ua);
      const isChrome = /(?:Chrome|CriOS)/.test(ua);
      const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
      const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
      const isPc = !isPhone && !isAndroid && !isSymbian;

      return {
        isTablet,
        isPhone,
        isAndroid,
        isChrome,
        isPc
      };
    } catch (e) {
      return {
        isTablet: false
      };
    }
  }

  render() {
    const {
      bodyClass,
      children
    } = this.props;

    const {
      isTablet,
      isPc
    } = this.state;

    return (
      <>
        {/* <SEO /> */}
        <SEO title="魔鬼鱼官网" />
        <Helmet>
          <meta
            name="keywords"
            content="魔鬼鱼、魔鬼鱼订货商城、魔鬼鱼订货平台、宠物订货 、宠物采购、主粮采购、宠物药品采购"
          />
        </Helmet>
        <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
          {
            (isTablet || isPc)
              ? [
                <div id="wrapper" className="wrapper" key="container">
                  <Header />
                  {children}
                </div>,
                <SubFooter key="footer" />]
              : <Redirect key="redirect" />
          }
        </div>
      </>
    );
  }
}

export default Layout;
