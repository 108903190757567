import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
const SubFooter = () => {
  const data = useStaticQuery(graphql`
    query SubFooterQuery {
      configJson {
        footer {
          copyright_text
          copyright_link
          copyright_detail_number
          copyright_detail_checked_logo
          copyright_detail_checked_number
          contact_company
          contact_address
          contact_mail
          contact_call
          contact_work_hour
          product_center_b2b
          product_center_solution
          product_center_chain
          copyright_logo
          ICP_number
          ICP_link
        }
      }
    }
  `);
  return (
    <div className="sub-footer">
      <div className="sub-footer_wrapper">
        <div className="sub-footer_row">
          <div className="sub-footer_left">
            <h3 className="footer-h3">联系我们</h3>
            <ul>
              <li>
                {data.configJson.footer.contact_company}
              </li>
              <li>
                {data.configJson.footer.contact_address}
              </li>
              <li>
                邮箱：
                <a
                  className="mail"
                  href={`mailto:${data.configJson.footer.contact_mail}`}
                >
                  {data.configJson.footer.contact_mail}
                </a>
              </li>
              {/* <li>
                联系电话：
                <a
                    className="mail"
                >
                  {data.configJson.footer.contact_call}
                </a>
              </li> */}
              <li>
                {data.configJson.footer.contact_work_hour}
              </li>
            </ul>
          </div>
          <div>
            <h3 className="footer-h3">产品中心</h3>
            <ul>
              <li>
                {data.configJson.footer.product_center_b2b}
              </li>
              <li>
                {data.configJson.footer.product_center_solution}
              </li>
              <li>
                {data.configJson.footer.product_center_chain}
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <div className="sub-footer-inner">
              <img
                className="copyright_logo"
                src={data.configJson.footer.copyright_logo}
                alt="mantas Logo"
              />
              <div className="copyright">
                <span>{data.configJson.footer.copyright_text}</span>
              </div>
              <div className="copyright">
                <span>{data.configJson.footer.copyright_detail_number}</span>
                <a
                  href={data.configJson.footer.ICP_link}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  {data.configJson.footer.ICP_number}
                </a>
              </div>
              <div className="copyright">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502016332"
                  className="copyright_police_box"
                >
                  <img
                    className="copyright_police_logo"
                    src={data.configJson.footer.copyright_detail_checked_logo}
                    alt="公安Logo"
                  />
                  <span className="copyright_police_number">
                    {data.configJson.footer.copyright_detail_checked_number}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
